@font-face {
  font-family: 'Raleway';
  src: url(./assets/Raleway.ttf);
  font-weight: 1 999;
}

@font-face {
  font-family: 'Raleway Italic';
  src: url(./assets/RalewayItalic.ttf);
  font-weight: 1 999;
}

@font-face {
  font-family: 'Work Sans';
  src: url(./assets/WorkSans.ttf);
  font-weight: 1 999;
}

@font-face {
  font-family: 'Work Sans Italic';
  src: url(./assets/WorkSansItalic.ttf);
  font-weight: 1 999;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  outline: 0;
}

html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}

body {
  background: #252627;
  height: 100%;
  overflow-x: inherit;
}

/** Footer **/
#pageWrap {
  min-height: 100vh;
  margin-bottom: -8rem;
}

#pageWrap:after {
  content: '';
  display: block;
}

#footer,
#pageWrap:after {
  height: 8rem;
}

/* Footer Links Hover */
.footerLink:hover {
  text-decoration: underline;
}

/** Mobile Nav Menu **/
.menuBtn {
  position: fixed;
  top: 34px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 19px;
  cursor: pointer;
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

.menuBtnBurger {
  width: 100%;
  height: 3px;
  background: #c5a973;
  border-radius: 1px;
  transition: all 0.5s ease-in-out;
}

.menuBtnBurger::before,
.menuBtnBurger::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #c5a973;
  border-radius: 1px;
  transition: all 0.5s ease-in-out;
}

.menuBtnBurger::before {
  transform: translateY(-0.5rem);
}

.menuBtnBurger::after {
  transform: translateY(0.5rem);
}

/* Mobile Nav Menu Animation */
.menuBtn.open .menuBtnBurger {
  background: transparent;
}

.menuBtn.open .menuBtnBurger::before {
  transform: rotate(45deg);
}

.menuBtn.open .menuBtnBurger::after {
  transform: rotate(-45deg);
}

/* Mobile Link Hover Effect */
.mobileHover {
  border-bottom: solid 1px;
  border-bottom-color: transparent;
  transition: border-bottom-color 0.3s ease-in-out;
}

.mobileHover:hover {
  border-bottom: solid 1px;
  border-bottom-color: #c5a973;
}

/** Desktop Nav Links **/
.activeLink {
  border-bottom: solid 1px;
  border-bottom-color: #c5a973;
  transition: border-bottom-color 0.3s ease-in-out;
}

.inactiveLink {
  border-bottom: solid 1px;
  border-bottom-color: transparent;
  transition: border-bottom-color 0.3s ease-in-out;
}

.inactiveLink:hover {
  border-bottom: solid 1px;
  border-bottom-color: #c5a973;
}

/** Carousel **/
.carousel {
  overflow: hidden;
  position: relative;
}

.inner {
  transition: transform 0.6s ease;
}

.innerWrap {
  white-space: nowrap;
}

.carouselItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  width: 100%;
  opacity: 30%;
  color: #252627;
  transition: height 0.3s ease-in-out;
}

/** Gallery **/
.filter {
  font: 200;
  color: #252627;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.75rem;
  max-height: 0;
  transition: max-height 0.3s ease;
}

/* .galleryCarouselContainer {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.galleryCarouselWrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.galleryCarouselContentWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
} */

.galleryCarouselItem {
  display: inline-flex;
  height: fit-content;
  color: #252627;
  transition: height 0.3s ease-in-out;
}

.show-3 > * {
  width: calc(100% / 3);
}

.galleryImg {
  width: 100% !important;
}

.leftArrow {
  color: #eaeaea;
  font-size: 2rem;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #252627;
  border: 1px solid #eaeaea;
}

.rightArrow {
  color: #eaeaea;
  font-size: 2rem;
  position: absolute;
  z-index: 100;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #252627;
  border: 1px solid #eaeaea;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  position: absolute !important;
  color: #eaeaea !important;
  font-size: 60px !important;
}

.alice-carousel__prev-btn-item {
  left: 0 !important;
  top: 25% !important;
}

.alice-carousel__next-btn-item {
  right: 0 !important;
  top: 25% !important;
}

/** Vendors **/
.vendorCat {
  background-color: none;
  border-top: solid 1px #c5a973;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  overflow: hidden;
  transition: padding-bottom 0.35s ease;
}

.vendorCatTitle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway';
  font-weight: 200;
  color: #eaeaea;
  transition: 0.4s ease-in-out;
}

.vendorList {
  text-align: center;
  font-family: 'Work Sans';
  font-weight: 200;
  color: #252627;
  background-color: #dac9aa;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}

.vendorList ul {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/* Arrow Transition */
.arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: fit-content;
  transition: all 0.4s ease-in-out;
}

.arrowLine::before,
.arrowLine::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1.7px;
  background: #eaeaea;
  border-radius: 0.5px;
  transition: transform 0.3s ease-in-out;
}

.arrowLine::before {
  transform: rotate(50deg);
}

.arrowLine::after {
  transform: translateX(0.46rem) rotate(-50deg);
}

.open .arrowLine::before {
  transform: translateX(0.46rem) rotate(50deg);
}

.open .arrowLine::after {
  transform: translateX(0rem) rotate(-50deg);
}

/* Vendor Links */
.vendorLi {
  border-bottom: solid #dac9aa 1px;
  border-top: solid #dac9aa 1px;
  transition: all 0.2s ease-in-out;
}

.vendorLi:hover {
  border-bottom: solid #c5a973 1px;
  border-top: solid #c5a973 1px;
  transition: all 0.2s ease-in-out;
}

/** Media Query **/
@media (hover: none) and (pointer: coarse) {
  .leftArrow,
  .rightArrow {
    display: none;
  }
}

@media screen and (min-width: 400px) {
  .carouselItem {
    height: 300px;
  }
}

@media screen and (min-width: 640px) {
  .carouselItem {
    height: 370px;
  }
}

@media screen and (min-width: 768px) {
  .menuBtn {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .carouselItem {
    height: 430px;
  }
}

@media screen and (min-width: 1140px) {
  .carouselItem {
    height: 500px;
  }
}

@media screen and (min-height: 1024px) {
  #pageWrap {
    min-height: 100vh;
    margin-bottom: -14rem;
  }

  #pageWrap:after {
    content: '';
    display: block;
  }

  #footer,
  #pageWrap:after {
    height: 14rem;
  }
}
